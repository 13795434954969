import * as React from "react"
import MyPic from "../images/my-picture.jpg"

export function AboutMe(){
  return (
    <div className='about-me'>
      <div className="flex justify-between">
        <div className='w-3/4 '>
          <p>I am from Dhaka, Bangladesh. After completing my Bachelors in Computer Science and Engineering from University of Dhaka, I've been working as a fullstack developer for the past 3+ years. I've worked in multiple companies and developed applications for the web both for the Bangladesh and US market.</p>

          <p>My dream is to make the meaning of my name come true. My name comes from Arabic which means positive improvement. I wish to bring positive improvement for me, my society, my country, and the whole world. I've taken technology and more specifically IT as my method. I love to develop software that brings value to people. </p>

          <p>Some of the technologies that I've recently worked with: </p>
            <ul className='grid grid-cols-4 list-disc list-inside'>
              <li>Python</li>
              <li>Django</li>
              <li>Javascript</li>
              <li>Typescript</li>
              <li>React</li>
              <li>PostgreSQL</li>
              <li>MySQL</li>
              <li>DynamoDB</li>
              <li>Nginx</li>
              <li>AWS</li>
            </ul>
        </div>
        <div className="flex justify-end">
          <img src={MyPic} alt="" style={{height: '300px', borderRadius: '40px'}}/>
        </div>
      </div>

    </div>
  )
}
